<template>
    <div class="page_content">
        <!-- 导航 -->
        <div class="page_left">
            <section>
                <h3 class="hd">Category</h3>
                <ul class="nav-cat">
                    <li class="cat-item" v-for="(item,index) in itemList" :key="index">
                        {{item.name}}
                        <div class="sub-menu">
                            <ul>
                                <li class="cat-item" v-for="(items,indexs) in item.tag" :key="indexs">
                                    <a  @click="catli(items)">
                                        {{items.name}}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </section>
        </div>
        <!-- 内容 -->
        <div class="page_right">
            <div class="content">
                <router-view :key="key"></router-view>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            itemList:[],
            madeNum:1
        }
    },
    computed:{
        key(){
            return this.$route.path + Math.random()
        }
    },
    methods: {
        getapp(){
            this.axios.indexPage({

            }).then(res=>{
                if(res.data.code==0){
                    this.itemList = res.data.data.menu
                }else{
                    this.itemList  = []
                }
            }).catch(err=>{

            })
        },
        down(){
            this.madeNum = 0
        },
        catli(val){
            if(document.body.scrollWidth < 768){
                this.$emit('getMessage', 1);
                this.$router.push({name:'PPThome',query:{tagId:val.id,tag:val.tag,itemName:val.name,type:1}})
            }else{
                this.$router.push({name:'PPThome',query:{tagId:val.id,tag:val.tag,itemName:val.name,type:1}})
            }
        }
    },
    mounted() {
        this.getapp()
        this.$nextTick(function () {
            setTimeout(()=>{
                window.onbeforeunload = function(){
                    document.documentElement.scrollTop = 0;  //ie下
                    document.body.scrollTop = 0;  //非ie
                }    
            },100)
        });
    }
}
</script>
<style scoped>
@import './../../styles/Page/Page.css';
</style>
<style>
.bread-crumbs .el-breadcrumb{
    line-height: 40px;
}
</style>